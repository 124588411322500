<template>
  <div class="theme-bg fill-height pa-0 ma-0 overflow-y-auto split">
    <v-tabs
      v-model="tab"
      class="px-4"
      background-color="theme-bg"
      :grow="false"
    >
      <v-tab v-for="(item, index) in tabComponent" :key="index">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="theme-bg" touchless>
      <v-tab-item v-for="(item, index) in tabComponent" :key="index">
        <component class="theme-bg" :is="item.component" v-if="index === tab" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: null,
    tabComponent: [
      {
        name: 'แผนรายการ',
        component: () => import('../components/budget/BudgetPlanTab.vue'),
        tab: 0
      },
      {
        name: 'รายการงบประมาณ',
        component: () => import('../components/budget/BudgetListTab.vue'),
        tab: 1
      }
    ]
  })
}
</script>
<style lang="scss" scoped>
.split {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
